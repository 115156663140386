window.Main = new (function () {

    var $windowWidth = $(window).width();
    var that = this;

    //Mobile functions
    let destructor = function () {
        $('.navSite').detach().appendTo('.DskNavBox');
        $('body').removeClass('noScroll');
    }

    let constructor = function () {
        $('.navSite').detach().prependTo('.sdNav');
    }

    this.matchesMenu = function () {
        let breackpoint = 1023,
            mql = window.matchMedia("(max-width:" + breackpoint + "px)");

        mediaqueryresponse(mql)

        mql.addListener(mediaqueryresponse)

        function mediaqueryresponse(mql) {
            if (mql.matches) {
                constructor();
            } else {
                destructor();
            }
        }
    }

    //open-close menu
    this.menuToggle = function () {
        $('body').toggleClass('noScroll');
    }

    /**
     * Init
     */
    this.init = function () {

        //mobile menu
        that.matchesMenu();
        $('.hamburger').on('click', that.menuToggle);
        that.headerClass();

        $('.goTo').on('click', that.scrollToElement);

    };

    this.scrollToElement = function () {
        let goToElement = $(this).data('place');

        $('.ii-navLeft li a').removeClass('active');
        $(this).addClass('active');

        $('.hamburger').trigger('click');

        $('html, body').animate({
            scrollTop: $("#" + goToElement).offset().top
        }, 1000);
    };

    /**
     * add class to header on scroll
     */
    this.headerClass = function () {
        $(window).scroll(function () {
            if ($(window).scrollTop() > 15) {
                $('header').addClass('stick')
            } else {
                $('header').removeClass('stick')
            }
            if ($('body').hasClass('is_team')) {
                $('header').addClass('stick');
            }
        })
    }

    this.librarySlider = function () {

        $('.carousel').slick({
            dots: false,
            prevArrow: $('.prev.maintenance'),
            nextArrow: $('.next.maintenance'),
            slidesToShow: 1,
            infinite: false
        });

    };


})();

$(window).resize(function () {
    var $windowWidth = $(window).width();
    if ($windowWidth > 991) {
        //Main.unSlick();
    } else {
        //Main.init();
    }
});
